.home-banner{
    width: 100%;
    height: 178px;
    background-image: url('../../../images/head-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-left: 15%;
    display: flex;
    align-items: center;
}
.head-title-text{
    font-size: 24px;
    font-weight: 700;
    color: #3d3d3d;
    line-height: 35px;
}
.head-des-text{
    font-size: 14px;
    font-weight: 400;
    color: #3d3d3d;
    line-height: 30px;
}

.overview{
    margin-top: 16px;
    padding: 24px;
    background-color: #fff;
}
.overviews{
    display: flex;
    justify-content: space-between;
}
.home-mrbtm{
    margin-bottom: 10px;  
}
.home-title{
    /* border-left: 4px solid rgb(68, 161, 250); */
padding-left: 10px;
font-size: 16px;
font-weight: 700;
position: relative;
}
.home-title::before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 4px;
    height: 22px;
    background-color: #2553a4;
}

.overviews .item{
    flex: 1;
    padding: 24px;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 6px 8px 0 8px;
}
.overviews .item1{
    background-color: #ffe9ed;
}
.overviews .item2{
    background-color: #e4eeff;
}
.overviews .item3{
    background-color: #e4eeff;
}
.overviews .item4{
    background-color: #d7f0e2;
}
.overviews .itemimg{
    width: 44px;
    height: 44px;
}
.overviews .itemt{
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, .898);
    line-height: 20px;
}
.overviews .itemc{
    font-size: 24px;
    font-weight: 700;
    color: rgba(0, 0, 0, .898);
    line-height: 35px;
}
.home-bottom .chartbox{
    height: 100%;
    background-color: #fff;
    padding: 5px 15px 30px 15px;
}
.home-bottom-bg-wrap{
    display: flex;
    flex-wrap: wrap;
}
.home-bottom-bg{
    width: 50%;
    height: 26vh;
    font-size: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #fff;
}
.dashboard{
    width: 100%;
    background-color: #f2f2f2;
    padding-bottom: 30px;
}
.dashboard__container{
    background: url('./images/login.jpg') no-repeat center;
    width: 100%;
    height: 100vh;
    background-size: 100% 100%;
}
.dashboard .header-box{
    background: #3553a4;
    background-color: rgba(53, 83, 164, .9);
    width: 100%;
    height: 64px;
    box-shadow: 0 4px 6px rgba(53, 83, 164, .05);
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 100;
}
.dashboard .dashboard__header{
    width: 1200px;;
    display: flex;
    position: relative;
    height: 52px;
    padding: 10px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
}
.dashboard .headerlogo{
    background: url('./images/logo1.png') no-repeat center center;
    background-size: 100% 100%;
    height:30px;
    width: 94px;
}
.dashboard .btn1{
    background-color: #1890ff !important;
    border-radius: 0 !important;
    border: none !important;
    color: #fff;
}
.dashboard .btn2{
    color: #2553a4 !important;
    border-radius: 0 !important;
    border: none !important;
    background-color: #fff !important;
}
.dashboard .btn1:hover {
    color: #2553a4 !important;
    background-color: #fff !important;
}
.dashboard .dashboard__body {
    /* background-color: #2553a4; */
    width: 100%;
    height: calc(100% - 64px);
    position: absolute;
    overflow: hidden;
    top: 64px;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.dashboard .logoimg {
    height: 43px;
    width: 150px;
    margin-bottom: 30px;
}
.dashboard .content_title {
    font-size: 36px;
    font-weight: 700;
    color: #fff;
}
.dashboard .content_des {
    font-size: 18px;
    font-weight: 400;
    color: #b7cffa;
    line-height: 32px;
}
.dashboard .btn3 {
    background-color: #fff !important;
    border-radius: 0 !important;
    color: #2553a4;
    font-size: 24px !important;
    margin-top: 30px;
    cursor: pointer;
    padding: 8px 24px;
}
.index-affix{
    bottom: 380px;
    right: 20px;
    position: fixed;
    z-index: 4;
}
.popovericon{
    width: 66px;
    height: 66px;
    background: #fff;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .04);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #dcdcdc;
    margin-bottom: 10px;
    font-size: 18px;
}
.popovericon:hover .anticon{
    color: #2553a4;
}

.dashboard-common{
    background-color: #fff;
    padding-top: 80px;
    padding-bottom: 50px;
}
.dashboard-common .common-title{
    margin-top: 80px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    color: #333;
}
.dashboard-common .common-des{
    font-size: 14px;
    font-weight: 400;
    color: #979797;
    line-height: 20px;
    margin-bottom: 15px;
    text-align: center;
}
.dashboard-common .secheme-bg{
    display: flex;
    align-items: center;
    justify-content: center;
}
.dashboard-common .secheme-label{
    width: 23px;
    height: 13px;
    margin-right: 10px;
}
.dashboard-common .secheme-label1{
    background-color: #4cedde;
}
.dashboard-common .secheme-label2{
    background-color: #ff7f16;
}
.dashboard-common .secheme-label3{
    background-color: #1890ff;
}
.dashboard-common .secheme-content{
    font-size: 12px;
    font-weight: 400;
    color: #3d3d3d;
}
.dashboard-common .secheme-img{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 30px;
    width: 1200px;
}
.dashboard-common .secheme-img img{
    display: inline-block;
    width: 787px;
    height: auto;
    vertical-align: middle;
}

.yw-box{
    width: 1200px;
    margin: 0 auto;
}
.yw-box .ywline{
    display: flex;
}
.yw-box .yw-item{
    flex: 1;
    padding: 48px 24px;
    margin-bottom: 30px;
    margin-top: 20px;
}
.yw-box .yw-item:hover{
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .1);
}
.yw-box .yw-item img{
    margin-bottom: 30px;
}
.yw-box .yw-item .yw-title{
    font-size: 18px;
    font-weight: 700;
    color: #333;
    margin-bottom: 10px;
}
.yw-box .yw-item .yw-des{
    font-size: 14px;
    color: #666;
}

.cps-box{
    width: 1200px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin: 0 auto;
}

.cps-box .cps-title{
    margin-top: 80px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    color: #333;
}
.cps-box .cps-des{
    font-size: 14px;
    font-weight: 400;
    color: #979797;
    line-height: 20px;
    margin-bottom: 15px;
    text-align: center;
}

.cps-box .yw-box .yw-item{
    margin: 12px;
    background-color: #fff;
}


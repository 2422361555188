.header{
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 48px !important;
}
.header-right{
    display: flex;
    align-items: center;
}
.username{
    margin-right: 20px;
}
.logo {
    background: url('../../images/logo1.png') no-repeat center 2px;
    height: 38px;
    width: 130px;
    background-size: contain;
    margin-right: 50px;
}
.ant-layout-header{
    padding: 0 32px !important;
    height: 48px !important;
    background: #2553a4!important;
}
.my-layout-Sider{
    height: calc(100vh - 48px);
    overflow-y: auto;
    overflow-x: hidden;
}
.my-layout-Sider::-webkit-scrollbar {
    width: 0px;
}
.site-layout{
    height: calc(100vh - 48px);
}
.myLayout{
    height: 100vh;
}
.ant-dropdown-link{
    color: #fff;
}
.header-left{
    display: flex;
    align-items: center;
}
.aSelect{
    background-color: #2553a4;
}
.aSelect .ant-select-selector{
    background-color: #2553a4 !important;
    color: #fff !important;
    border: 1px solid #d9d9d9 !important;
    /* border-radius: 10px !important; */
}
.aSelect .ant-select-arrow{
    color: #fff !important;
}
.myContent{
    padding: 16px;
    min-height: calc(100vh - 86px) !important;
}
.myFooter{
    text-align: center;
    padding: 0 0 12px 0 !important;
}
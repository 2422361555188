.header{
  /* position: 'sticky';
  top: 0;
  z-index: 1; */
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 48px !important;
}
.header-right{
  display: flex;
  align-items: center;
}
.username{
  margin-right: 20px;
}
.logo{
  background: url('./images/logo1.png') no-repeat center 2px;
  height: 38px;
  width: 130px;
  background-size: contain;
}
.ant-layout-header{
  padding: 0 32px !important;
  height: 48px !important;
  background: #2553a4!important;
}
.my-layout-Sider{
  height: calc(100vh - 48px);
  overflow-y: auto;
  overflow-x: hidden;
}
.my-layout-Sider::-webkit-scrollbar {
  width: 0px;
}
.site-layout{
  height: calc(100vh - 48px);
}
.myLayout{
  height: 100vh;
}
.ant-dropdown-link{
  color: #fff;
}

.ant-form {
  background: #fff !important;
  padding: 10px !important;
}
.ant-table-wrapper {
  margin-top: 20px !important;
}

/* 登录 */
.login-tab{
  position: absolute;
  width:350px;
  height: 340px;
  top: 0;
  bottom: 0;
  right: 15%;
  margin: auto 0;
  /* margin: 10% 15% 0 0 !important; */
  background-color: #fff;
  padding: 30px !important;
  border-radius: 15px;
}
.site-form-item-icon{
  color: rgba(0, 0, 0, 0.85) !important;
}
.login-wrap{
  position: relative;
  background: url('./images/login.jpg') no-repeat center;
  height: 100vh;
  background-size: 100% 100%;
  display: flex;
  justify-content: flex-end;
}
.copyright{
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 500;
  color: #fff;
}
.logo-txt{
  color: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  left: 15%;
}
.logo-t1{
  width: 150px;
  height: 43px;
}
.sysname{
  font-size: 24px;
  margin-top: 20px;
  color: #fff;
  font-weight: 700;
  line-height: 24px;
}
.systits{
  font-size: 24px;
  color: #fff;
  line-height: 24px;
  margin-top: 20px;
}
.sysline{
  width: 100px;
  height: 4px;
  background: #fff;
  margin-top: 30px;
}
.login-form,
.login-form .ant-input-affix-wrapper,
.login-form .ant-input{
  background: transparent !important;
}
.login-form .ant-input{
  height: 28px;
}
.ant-input-affix-wrapper{
  border: 1px solid #f6f8fd;
}
/* .login-form .ant-input-prefix,
.login-form .ant-input{
  color: #ffffff7a;
} */
.login-form-button{
  width: 100%;
  border-radius: 20px !important;
  height: 42px !important;
  margin-top: 30px !important;
  /* background: #011d37; */
  border: none;
}
.login-form-button:hover, .login-form-button:focus{
  /* background: #0f416f; */
  border: none;
}
.login-wrap .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #2553a4;
  
}
.lgcode-box{
  display: flex;
  align-items: center;
}
.lgcode-input{
  margin-right: 10px;
  flex: 1;
}
.lgcode-btn{
  width: 100px;
  height: 36px !important;
}
.ant-table-pagination{
  margin-bottom: 0px !important;
}
.site-page-header{
  background-color: #fff !important;
  padding-top: 0px !important;
  padding-bottom: 6px !important;
}

.inputBox {
    position: relative;
    overflow: hidden;
  }
  .field-list {
    display: flex;
  }
  .field-item {
    box-sizing: border-box;
    width: 22px;
    height: 32px;
    line-height: 32px;
    font-size: 20px;
    color: #363a44;
    text-align: center;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    margin: 0 2px;
  }
  
  .field-item-focus::before {
    content: "";
    display: block;
    width: 1px; 
    height: 20px;
    margin: 5px auto;
    background: #428ffc;
    animation: blink 1s steps(1) infinite;
  }
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
  .field-input {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 32px;
    padding: 0;
    border: none;
    outline: none;
    opacity: 0;
    background: transparent;
  }
  
  